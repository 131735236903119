export function generateTooltip() {
    const tooltipElements = document.querySelectorAll("[vdx-tooltip-top], [vdx-tooltip-bottom], [vdx-tooltip-left], [vdx-tooltip-right]");
    tooltipElements.forEach(element => {
        element.style.position = 'relative';
        element.style.cursor = 'default';
        element.addEventListener('mouseenter', () => {
            const tooltip = document.createElement('div'); 
            tooltip.classList.add('vdx--tooltip');
            tooltip.innerHTML = element.getAttribute('vdx-tooltip-top') || element.getAttribute('vdx-tooltip-bottom') || element.getAttribute('vdx-tooltip-left') || element.getAttribute('vdx-tooltip-right');
            tooltip.style.position = 'absolute';
            const tooltipColor = '#333';
            tooltip.style.backgroundColor = tooltipColor;
            tooltip.style.color = 'white';
            tooltip.style.padding = '.5rem';
            tooltip.style.borderRadius = '0.25rem'; 
            tooltip.style.zIndex = '1000';
            const position = element.getAttribute('vdx-tooltip-top') ? 'top' : element.getAttribute('vdx-tooltip-bottom') ? 'bottom' : element.getAttribute('vdx-tooltip-left') ? 'left' : element.getAttribute('vdx-tooltip-right') ? 'right' : 'bottom';
            switch (position) {
                case 'top':
                    tooltip.style.top = '0';
                    tooltip.style.left = '50%';
                    tooltip.style.transform = 'translateX(-50%) translateY(-100%)';
                    break;
                case 'bottom':
                    tooltip.style.top = '100%';
                    tooltip.style.left = '50%';
                    tooltip.style.transform = 'translateX(-50%)';
                    break;
                case 'left':
                    tooltip.style.top = '50%';
                    tooltip.style.left = '0';
                    tooltip.style.transform = 'translateY(-50%) translateX(-100%)';
                    break;
                case 'right':
                    tooltip.style.top = '50%';
                    tooltip.style.left = '100%';
                    tooltip.style.transform = 'translateY(-50%)';
                    break;
                default:
                    tooltip.style.top = '100%';
                    tooltip.style.left = '50%';
                    tooltip.style.transform = 'translateX(-50%)';
            }
            const arrow = document.createElement('div');
           
            arrow.style.position = 'absolute';
            arrow.style.width = '0';
            arrow.style.height = '0';
            switch (position) {
                case 'top':
                    arrow.classList.add('vdx--tooltip-arrow-down');
                    arrow.style.top = '100%';
                    arrow.style.left = '50%';
                    arrow.style.transform = 'translateX(-50%)';
                    arrow.style.borderTop = '0.25rem solid #333';
                    arrow.style.borderBottom = '0.25rem solid transparent';
                    arrow.style.borderLeft = '0.25rem solid transparent';
                    arrow.style.borderRight = '0.25rem solid transparent';
                    break;
                case 'bottom':
                    arrow.classList.add('vdx--tooltip-arrow-up');
                    arrow.style.bottom = '100%';
                    arrow.style.left = '50%';
                    arrow.style.transform = 'translateX(-50%)';
                    arrow.style.borderBottom = '0.25rem solid #333';
                    arrow.style.borderTop = '0.25rem solid transparent';
                    arrow.style.borderLeft = '0.25rem solid transparent';
                    arrow.style.borderRight = '0.25rem solid transparent';
                    break;
                case 'left':
                    arrow.classList.add('vdx--tooltip-arrow-left');
                    arrow.style.left = '100%';
                    arrow.style.top = '50%';
                    arrow.style.transform = 'translateY(-50%)';
                    arrow.style.borderLeft = '0.25rem solid #333';
                    arrow.style.borderRight = '0.25rem solid transparent';
                    arrow.style.borderTop = '0.25rem solid transparent';
                    arrow.style.borderBottom = '0.25rem solid transparent';
                    break;
                case 'right':
                    arrow.classList.add('vdx--tooltip-arrow-right');
                    arrow.style.right = '100%';
                    arrow.style.top = '50%';
                    arrow.style.transform = 'translateY(-50%)';
                    arrow.style.borderRight = '0.25rem solid #333';
                    arrow.style.borderLeft = '0.25rem solid transparent';
                    arrow.style.borderTop = '0.25rem solid transparent';
                    arrow.style.borderBottom = '0.25rem solid transparent';
                    break;
                default:
                    arrow.classList.add('vdx--tooltip-arrow-up');
                    arrow.style.bottom = '100%';
                    arrow.style.left = '50%';
                    arrow.style.transform = 'translateX(-50%)';
                    arrow.style.borderBottom = '0.25rem solid #333';
                    arrow.style.borderTop = '0.25rem solid transparent';
                    arrow.style.borderLeft = '0.25rem solid transparent';
                    arrow.style.borderRight = '0.25rem solid transparent';
            }
            tooltip.appendChild(arrow);
            element.appendChild(tooltip);
            
            const tooltipWidth = tooltip.offsetWidth;
            const tooltipHeight = tooltip.offsetHeight;
            const windowWidth = window.innerWidth;
            const windowTop = window.scrollY;
            const tooltipRect = tooltip.getBoundingClientRect();
            const tooltipPosition = {
                top: tooltipRect.top + window.scrollY,
                left: tooltipRect.left + window.scrollX,
                right: tooltipRect.right + window.scrollX,
                bottom: tooltipRect.bottom + window.scrollY
            };
            const tooltipOffsetFromWindowTop = tooltipPosition.top - windowTop;
            if (tooltipOffsetFromWindowTop < 16) {
                tooltip.style.top = '100%';
                tooltip.style.left = '50%';
                tooltip.style.transform = 'translateX(-50%)';
                arrow.style.top = 'auto';
                arrow.classList.remove('vdx--tooltip-arrow-down');
                arrow.classList.add('vdx--tooltip-arrow-up');
                arrow.style.bottom = '100%';
                arrow.style.left = '50%';
                arrow.style.transform = 'translateX(-50%)';
                arrow.style.borderBottom = '0.25rem solid #333';
                arrow.style.borderTop = '0.25rem solid transparent';
                arrow.style.borderLeft = '0.25rem solid transparent';
                arrow.style.borderRight = '0.25rem solid transparent';
            }
            const tooltipOffsetFromWindowLeft = tooltipPosition.left;
            if (tooltipOffsetFromWindowLeft < 16) {
                console.log('Tooltip is too close to the left of the window');
                tooltip.style.top = '50%';
                tooltip.style.left = '100%';
                tooltip.style.transform = 'translateY(-50%)';
                arrow.classList.remove('vdx--tooltip-arrow-left');
                arrow.classList.add('vdx--tooltip-arrow-right');
                arrow.style.right = '100%';
                arrow.style.left = 'auto';
                arrow.style.top = '50%';
                arrow.style.transform = 'translateY(-50%)';
                arrow.style.borderRight = '0.25rem solid #333';
                arrow.style.borderLeft = '0.25rem solid transparent';
                arrow.style.borderTop = '0.25rem solid transparent';
                arrow.style.borderBottom = '0.25rem solid transparent';
            }
            const tooltipOffsetFromWindowBottom = windowTop + window.innerHeight - tooltipPosition.bottom;
            if (tooltipOffsetFromWindowBottom < 16) {
                console.log('Tooltip is too close to the bottom of the window');
                tooltip.style.top = '0';
                tooltip.style.left = '50%';
                tooltip.style.transform = 'translateX(-50%) translateY(-100%)';
                arrow.classList.remove('vdx--tooltip-arrow-up');
                arrow.classList.add('vdx--tooltip-arrow-down');
                arrow.style.top = '100%';
                arrow.style.left = '50%';
                arrow.style.transform = 'translateX(-50%)';
                arrow.style.borderTop = '0.25rem solid #333';
                arrow.style.borderBottom = '0.25rem solid transparent';
                arrow.style.borderLeft = '0.25rem solid transparent';
                arrow.style.borderRight = '0.25rem solid transparent';
            }
            const tooltipOffsetFromWindowRight = windowWidth - tooltipPosition.right;
            if (tooltipOffsetFromWindowRight < 16) {
                console.log('Tooltip is too close to the right of the window');
                tooltip.style.top = '50%';
                tooltip.style.left = '0';
                tooltip.style.transform = 'translateY(-50%) translateX(-100%)';
                arrow.classList.remove('vdx--tooltip-arrow-right');
                arrow.classList.add('vdx--tooltip-arrow-left');
                arrow.style.left = '100%';
                arrow.style.top = '50%';
                arrow.style.transform = 'translateY(-50%)';
                arrow.style.borderLeft = '0.25rem solid #333';
                arrow.style.borderRight = '0.25rem solid transparent';
                arrow.style.borderTop = '0.25rem solid transparent';
                arrow.style.borderBottom = '0.25rem solid transparent';
            }
            element.addEventListener('mouseleave', () => {
                const tooltip = document.querySelector('.vdx--tooltip');
                if (tooltip) {
                    tooltip.remove();
                }
            });
        });
    });
}