import { handleReadTimeOnPageLoad, processReadTimeLink } from './readtime.js';
import { socialShare } from './socialshare.js';
import { formatDate } from './date.js';
import { currentTime } from './time.js';
import { onClick } from './onclick.js';
import { copyToClipboard } from './copyclip.js';
import { popupsIntent } from './popup.js';
import { cmsNest } from './cmsnest.js';
import { backButtons } from './backbutton.js';
import { generateTOC } from './toc.js';
import { transitionDelay } from './transitiondelay.js';
import { showNavScroll } from './shownavscroll.js';
import { initializeCountdowns } from './countdowntimer.js';
import { initializeToastButtons } from './toast.js';
import { countUp } from './countup.js';
import { customTabs, timedTabs } from './tabs.js';
import { handleChildHoverState } from './hoverstate.js';
import { generateTooltip } from './tooltip.js';


document.addEventListener("DOMContentLoaded", async () => {
    const nestLinks = document.querySelectorAll("[vdx-nest-link]");

    // If there are vdx-nest-link elements, run cmsNest first
    if (nestLinks.length > 0) {
        try {
            await cmsNest();
        } catch (error) {
            console.error("Error during cmsNest execution:", error);
        }
    }

    // Once cmsNest is done (or if no vdx-nest-link elements), run the other scripts
    document.querySelectorAll("[vdx-readtime-link]").forEach(processReadTimeLink);
    handleReadTimeOnPageLoad();

    socialShare();

    formatDate();

    currentTime();

    onClick();

    copyToClipboard();

    popupsIntent();

    backButtons();

    generateTOC();

    transitionDelay();

    showNavScroll();

    initializeCountdowns();

    initializeToastButtons();

    countUp();

    customTabs();
    timedTabs();

    handleChildHoverState();
    generateTooltip();

});



console.log('VisualDX is Magic');
